@import "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap";
@import "https://fonts.googleapis.com/css?family=Hind:500,600,700&display=swap";
html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

html, body {
  color: #595959;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.page-navigation-link {
  color: #595959;
  width: 100%;
  padding: 1rem;
  line-height: 1;
  text-decoration: none;
  transition: all .15s ease-out;
}

@media (min-width: 960px) {
  .page-navigation-link:hover {
    color: #03a678;
  }
}

.page-navigation-link.active {
  color: #03a678;
}

@media (min-width: 960px) {
  .page-navigation-link.active {
    color: #03a678;
    border-color: #03a678;
  }
}

.footer-navigation-link {
  color: inherit;
  width: 100%;
  line-height: 1.625;
  text-decoration: none;
  transition: all .15s ease-out;
}

.footer-navigation-link:hover {
  color: #03a678;
}

button, .button {
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.primary-button {
  color: #fff;
  text-align: center;
  background: #03a678;
  border: .0625rem solid #03a678;
  border-radius: .25rem;
  padding: .75rem 1rem;
  text-decoration: none;
  transition: all .15s ease-out;
  display: block;
}

@media (hover: hover) {
  .primary-button:hover {
    color: #f2f2f2;
    background: #027454;
  }
}

.outlined-link-button {
  color: #03a678;
  text-align: center;
  border: .0625rem solid #03a678;
  border-radius: .25rem;
  padding: .75rem 1rem;
  text-decoration: none;
  transition: all .15s ease-out;
  display: block;
}

@media (hover: hover) {
  .outlined-link-button:hover {
    color: #fff;
    background: #03a678;
  }
}

.mobile-navigation-menu {
  z-index: 10;
  padding: 1rem;
  transition: all .24s;
  position: absolute;
  top: 2.75rem;
  right: 0;
}

.mobile-navigation-menu > div {
  height: .125rem;
  width: 1.5rem;
  background-color: #595959;
  margin: .25rem 0;
  transition: all .24s;
}

.mobile-navigation-menu.opened {
  transform: rotate(270deg);
}

.mobile-navigation-menu.opened > div:first-child {
  transform: rotate(45deg)translate3d(4px, 4px, 0);
}

.mobile-navigation-menu.opened > div:last-child {
  transform: rotate(-45deg)translate3d(4px, -4px, 0);
}

.mobile-navigation-menu.opened > div:nth-child(2) {
  opacity: 0;
}

main {
  max-width: 71.25rem;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 1.5rem;
  margin: 2rem auto;
  padding: 0 1.5rem;
  display: grid;
}

@media (min-width: 960px) {
  main {
    margin: 8rem auto;
  }
}

article {
  width: 100%;
  margin: 0 auto 3rem;
  grid-column: 1 / -1;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 1.5rem;
  margin-bottom: auto;
  display: grid;
}

article header, article section {
  grid-column: 1 / -1;
}

@media (min-width: 960px) {
  article header, article section {
    grid-column: 2 / 12;
  }
}

article section {
  grid-column: 1 / -1;
  margin: 0 0 2rem;
  margin-bottom: auto;
}

@media (min-width: 960px) {
  article section {
    grid-column: 2 / 12;
  }
}

article section.two-cols {
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 2rem;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 960px) {
  article section.two-cols {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

article section.two-cols figure {
  width: 100%;
}

@media (min-width: 960px) {
  article section.two-cols figure {
    width: 80%;
  }
}

article figure {
  grid-column: 1 / -1;
}

@media (min-width: 960px) {
  article figure {
    grid-column: 2 / 12;
  }
}

h1, .h1 {
  margin: 0;
  font-family: Hind, sans-serif;
  font-size: 2rem;
  font-weight: 700;
}

@media (min-width: 425px) {
  h1, .h1 {
    font-size: 3rem;
  }
}

@media (min-width: 960px) {
  h1, .h1 {
    font-size: 4rem;
  }
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 5rem;
  }
}

h2, .h2 {
  color: #03a678;
  margin: 0 0 2rem;
  font-family: Hind, sans-serif;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.3;
}

h3, .h3 {
  color: #03a678;
  margin: 0 0 1rem;
  font-family: Hind, sans-serif;
  font-size: 1.3125rem;
  font-weight: 500;
  line-height: 1.3;
}

h4, .h4 {
  margin: 0 0 1rem;
  font-family: Hind, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3;
}

p {
  margin: 0 0 1.25rem;
  line-height: 1.625;
}

figure {
  margin: 0;
}

figure img {
  width: 100%;
  display: block;
}

figure video {
  width: 100%;
}

article ul, .ul {
  margin-bottom: .75rem;
  padding: 0 0 0 1rem;
}

article ul li, .ul li {
  line-height: initial;
  margin-bottom: .5rem;
  position: relative;
}

article ul li:last-child, .ul li:last-child {
  margin-bottom: 0;
}

article ul li:before, .ul li:before {
  content: "•";
  color: #03a678;
  font-weight: 700;
  display: block;
  position: absolute;
  left: -1rem;
}

ol {
  margin-bottom: .75rem;
  padding: 0 0 0 1rem;
}

ol li {
  line-height: initial;
  margin-bottom: .5rem;
  position: relative;
}

ol li:last-child {
  margin-bottom: 0;
}

.accordion {
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 1rem;
  display: -ms-flexbox;
  display: flex;
}

.accordion .item {
  text-align: left;
  color: #595959;
  cursor: pointer;
  background: #f2f2f2;
  border: .125rem solid #e6e6e6;
  border-radius: .5rem;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-positive: 1;
  flex-grow: 1;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr auto;
  -ms-flex-align: start;
  align-items: start;
  padding: 2rem;
  transition: all .15s;
  display: grid;
}

@media (min-width: 760px) {
  .accordion .item {
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (hover: hover) {
  .accordion .item:hover {
    background: rgba(3, 166, 120, .1);
    border: .125rem solid rgba(3, 166, 120, .4);
  }
}

.accordion .item .header {
  grid-row-start: 1;
  grid-column-start: span 2;
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.2;
}

@media (min-width: 760px) {
  .accordion .item .header {
    grid-column-start: 1;
  }
}

.accordion .item .content {
  grid-row-start: 2;
  grid-column-start: span 2;
  margin-top: 1rem;
  display: none;
  overflow-y: hidden;
}

.accordion .item .content p:last-child {
  margin-bottom: 0;
}

.accordion .item .metadata {
  grid-row-start: 3;
  grid-column-start: span 2;
  gap: 1rem;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: bold;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 760px) {
  .accordion .item .metadata {
    grid-row-start: 1;
    grid-column-start: 2;
    -ms-flex-pack: end;
    justify-content: end;
    margin-top: 0;
  }
}

.accordion .item .metadata div {
  -ms-flex-align: center;
  align-items: center;
  gap: .25rem;
  display: -ms-flexbox;
  display: flex;
}

.accordion .item.active {
  cursor: default;
  color: #595959;
  background: rgba(3, 166, 120, .1);
  border-color: rgba(3, 166, 120, .4);
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: start;
}

.accordion .item.active .content {
  grid-template-rows: 1fr;
  display: grid;
}

.accordion .item.active .metadata div:last-child {
  display: -ms-flexbox;
  display: flex;
}

.collaboration {
  grid-column: span 12;
  margin: 2rem auto;
}

@media (min-width: 960px) {
  .collaboration {
    margin: 5rem auto;
  }
}

.collaboration__header {
  text-align: center;
}

.collaboration__content {
  gap: 1rem;
  display: grid;
}

@media (min-width: 960px) {
  .collaboration__content {
    grid-template-columns: repeat(3, 1fr);
  }
}

.collaboration__content--card {
  color: #595959;
  background-color: #f2f2f2;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: .5rem;
  -ms-flex-direction: column;
  flex-direction: column;
  text-decoration: none;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

.collaboration__content--card figure {
  max-height: 10rem;
  display: -ms-flexbox;
  display: flex;
}

.collaboration__content--card figure img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.collaboration__content--card div {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 2rem;
  display: -ms-flexbox;
  display: flex;
}

.collaboration__content--card div p {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.competence-overview {
  grid-column: span 12;
  margin: 2rem auto;
  scroll-margin-top: 12rem;
}

@media (min-width: 960px) {
  .competence-overview {
    margin: 5rem auto;
  }
}

.competence-overview__header {
  text-align: center;
}

.competence-overview__content {
  gap: 1rem;
  display: grid;
}

@media (min-width: 960px) {
  .competence-overview__content {
    grid-template-columns: repeat(3, 1fr);
  }
}

.competence-overview__content--card {
  color: #595959;
  background-color: #f2f2f2;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: .5rem;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 2rem;
  text-decoration: none;
  transition: all .15s ease-out;
  display: -ms-flexbox;
  display: flex;
}

@media (hover: hover) {
  .competence-overview__content--card:hover {
    background-color: rgba(3, 166, 120, .1);
    border-color: #03a678;
  }
}

.competence-overview__content--card img {
  max-width: 2.5rem;
  margin-bottom: 2rem;
}

.emphasize-box {
  text-align: center;
  background: rgba(3, 166, 120, .1);
  border: .125rem solid rgba(3, 166, 120, .4);
  border-radius: .5rem;
  padding: 2rem;
}

.emphasize-box > * {
  margin: 0;
}

.emphasize-box:before {
  content: "";
  height: 3rem;
  width: 100%;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgZmlsbD0iIzAzYTY3OCIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0xNzYsMjMyYTgsOCwwLDAsMS04LDhIODhhOCw4LDAsMCwxLDAtMTZoODBBOCw4LDAsMCwxLDE3NiwyMzJabTQwLTEyOGE4Ny41NSw4Ny41NSwwLDAsMS0zMy42NCw2OS4yMUExNi4yNCwxNi4yNCwwLDAsMCwxNzYsMTg2djZhMTYsMTYsMCwwLDEtMTYsMTZIOTZhMTYsMTYsMCwwLDEtMTYtMTZ2LTZhMTYsMTYsMCwwLDAtNi4yMy0xMi42NkE4Ny41OSw4Ny41OSwwLDAsMSw0MCwxMDQuNDlDMzkuNzQsNTYuODMsNzguMjYsMTcuMTQsMTI1Ljg4LDE2QTg4LDg4LDAsMCwxLDIxNiwxMDRabS0xNiwwYTcyLDcyLDAsMCwwLTczLjc0LTcyYy0zOSwuOTItNzAuNDcsMzMuMzktNzAuMjYsNzIuMzlhNzEuNjUsNzEuNjUsMCwwLDAsMjcuNjQsNTYuM0EzMiwzMiwwLDAsMSw5NiwxODZ2Nmg2NHYtNmEzMi4xNSwzMi4xNSwwLDAsMSwxMi40Ny0yNS4zNUE3MS42NSw3MS42NSwwLDAsMCwyMDAsMTA0Wm0tMTYuMTEtOS4zNGE1Ny42LDU3LjYsMCwwLDAtNDYuNTYtNDYuNTUsOCw4LDAsMCwwLTIuNjYsMTUuNzhjMTYuNTcsMi43OSwzMC42MywxNi44NSwzMy40NCwzMy40NUE4LDgsMCwwLDAsMTc2LDEwNGE5LDksMCwwLDAsMS4zNS0uMTFBOCw4LDAsMCwwLDE4My44OSw5NC42NloiPjwvcGF0aD48L3N2Zz4=");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 1rem;
  display: block;
}

.formats-overview {
  grid-column: span 12;
  margin: 2rem auto;
  scroll-margin-top: 12rem;
}

@media (min-width: 960px) {
  .formats-overview {
    margin: 5rem auto;
  }
}

.formats-overview__header {
  text-align: center;
}

.formats-overview__content {
  gap: 1rem;
  display: grid;
}

@media (min-width: 960px) {
  .formats-overview__content {
    grid-template-columns: repeat(2, 1fr);
  }
}

.formats-overview__content--card {
  color: #595959;
  background-color: #f2f2f2;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: .5rem;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 2rem;
  text-decoration: none;
  transition: all .15s ease-out;
  display: -ms-flexbox;
  display: flex;
}

@media (hover: hover) {
  .formats-overview__content--card:hover {
    background-color: rgba(3, 166, 120, .1);
    border-color: #03a678;
  }
}

.formats-overview__content--card img {
  max-width: 2.5rem;
  margin-bottom: 2rem;
}

.intro-stage {
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  grid-column: 1 / 13;
  gap: 2rem;
  padding-top: 8rem;
  padding-bottom: 2rem;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 960px) {
  .intro-stage {
    -ms-flex-direction: row;
    flex-direction: row;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.intro-stage__content {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
}

.intro-stage__content h1 {
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.intro-stage__content h2 {
  color: #595959;
  margin-bottom: 2rem;
}

.intro-stage__content--actions {
  gap: 1rem;
  display: -ms-flexbox;
  display: flex;
}

.intro-stage__video {
  border-radius: .5rem;
  -ms-flex-preferred-size: 60%;
  flex-basis: 60%;
  margin: 0;
  overflow: hidden;
}

.intro-stage__video video {
  object-fit: cover;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
}

.page-footer {
  background-color: #f2f2f2;
  padding: 2.5rem 0;
}

.page-footer div {
  max-width: 71.25rem;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 1.5rem;
  margin: auto;
  padding: 0 1.5rem;
  display: grid;
}

.page-footer section:first-child {
  grid-column: 1 / -1;
}

@media (min-width: 960px) {
  .page-footer section:first-child {
    grid-column: 1 / 6;
  }
}

.page-footer section:nth-child(2) {
  grid-column: 1 / -1;
}

@media (min-width: 960px) {
  .page-footer section:nth-child(2) {
    grid-column: 6 / 9;
  }
}

.page-footer section:nth-child(3) {
  grid-column: 1 / -1;
}

.page-footer section:nth-child(3) p {
  margin-top: 3.8rem;
}

@media (min-width: 960px) {
  .page-footer section:nth-child(3) {
    grid-column: 10 / 12;
  }
}

.page-header {
  height: 6.75rem;
  z-index: 90;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  transition: all .2s ease-out;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

@media (min-width: 960px) {
  .page-header {
    height: auto;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
  }

  .page-header button {
    display: none;
  }
}

.page-header.opened {
  height: 24rem;
}

.page-header .logo {
  color: #fff;
  letter-spacing: 1px;
  width: 13rem;
  -ms-flex-line-pack: center;
  -ms-flex-pack: center;
  align-content: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  padding: .5rem 1rem;
  font-family: Hind, sans-serif;
  font-size: 21px;
  font-weight: 600;
  text-decoration: none;
  display: -ms-flexbox;
  display: flex;
}

.page-header__quick-contact {
  color: #fff;
  width: 100%;
  background-color: #595959;
  display: -ms-flexbox;
  display: flex;
}

.page-header__quick-contact ul {
  width: 100%;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: .5rem;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 960px) {
  .page-header__quick-contact ul {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

.page-header__quick-contact ul a {
  color: #fff;
  padding: .5rem;
  font-size: .75rem;
  text-decoration: none;
}

.page-header__main-nav {
  width: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 960px) {
  .page-header__main-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.page-header__main-nav ul {
  text-align: left;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem 0;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 960px) {
  .page-header__main-nav ul {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.page-header__main-nav ul li {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
}

.stage {
  height: 100vw;
  color: #fff;
  text-align: center;
  background-color: #595959;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 5rem;
  padding: 0 1rem;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 960px) {
  .stage {
    height: 70vh;
  }
}

.index {
  background-image: url("Closeup_Pascalle_Gruen.b3d3cb44.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 960px) {
  .index {
    background-position: 50% 40%;
    background-size: cover;
  }
}

@media (min-width: 1600px) {
  .index {
    background-position: 50% 40%;
    background-size: cover;
  }
}

.themes {
  background-image: url("portfolio.366917b4.jpg");
  background-position: right 40% center;
  background-size: cover;
}

@media (min-width: 960px) {
  .themes {
    background-position: center;
  }
}

.customer {
  background-image: url("battle-black-blur-board-game.d6d4362f.jpg");
  background-position: 46% 50%;
  background-size: cover;
}

@media (min-width: 960px) {
  .customer {
    background-position: 50% 20%;
  }
}

.formats {
  background-image: url("formats.4ec9c72b.jpg");
  background-position: 46% 50%;
  background-size: cover;
}

@media (min-width: 960px) {
  .formats {
    background-position: center;
  }
}

.privacy {
  background-image: url("privacy.e2816cd0.jpg");
  background-position: 46% 50%;
  background-size: cover;
}

@media (min-width: 960px) {
  .privacy {
    background-position: center;
  }
}

.imprint {
  background-image: url("impressum.294a2526.jpg");
  background-position: 46% 50%;
  background-size: cover;
}

@media (min-width: 960px) {
  .imprint {
    background-position: center;
  }
}

.matrix {
  background-image: url("matrix.dff5ecdc.jpg");
  background-position: center;
  background-size: cover;
}

@media (min-width: 960px) {
  .matrix {
    background-position: center;
  }
}

.team-organisation {
  background-image: url("team-orga.22e09df7.jpg");
  background-position: center;
  background-size: cover;
}

@media (min-width: 960px) {
  .team-organisation {
    background-position: center;
  }
}

.tab-content {
  grid-column: 1 / -1;
}

.tab-content nav ul {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  -ms-flex-direction: column;
  flex-direction: column;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 760px) {
  .tab-content nav ul {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.tab-content nav ul li {
  text-align: center;
  color: #595959;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 20%;
  flex-basis: 20%;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  padding: .75rem;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 760px) {
  .tab-content nav ul li {
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1rem;
  }
}

.tab-content nav ul li:hover {
  color: #03a678;
  cursor: pointer;
  background-color: rgba(3, 166, 120, .1);
}

.tab-content nav ul li.active {
  color: #03a678;
  background-color: rgba(3, 166, 120, .1);
}

.tab-content nav ul li img {
  width: 24px;
  margin-right: 1rem;
}

@media (min-width: 760px) {
  .tab-content nav ul li img {
    width: 32px;
    margin-bottom: 1rem;
    margin-right: 0;
  }
}

.tab-content .content {
  min-height: 30rem;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 1.5rem;
  margin: 3rem auto 0;
  display: grid;
}

.tab-content .content div {
  grid-column: 1 / -1;
}

@media (min-width: 960px) {
  .tab-content .content div {
    grid-column: 2 / 12;
  }
}

.tab-content .content div figure {
  margin: 2rem 0;
}

.tile-grid {
  grid-gap: 1.5rem;
  grid-column: 1 / -1;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-auto-flow: row;
  display: grid;
}

.tile-grid .customer-tile {
  text-align: center;
  min-height: 20rem;
  background-color: #f2f2f2;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  justify-items: center;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.tile-grid .customer-tile figure {
  width: 70%;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.tile-grid .customer-tile figure img {
  mix-blend-mode: darken;
}

.tile-grid .customer-tile .description {
  opacity: 0;
  text-align: center;
  color: #fff;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  background-color: rgba(89, 89, 89, .9);
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 2rem;
  transition: all .25s;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.tile-grid .customer-tile .description:hover {
  opacity: 1;
}

/*# sourceMappingURL=datenschutz.b145a5dd.css.map */
