.emphasize-box {
	background: transparentize($color-02, 0.9);
	border-color: transparentize($color-02, 0.6);
	border: 0.125rem solid transparentize($color-02, 0.6);
	border-radius: 0.5rem;
	padding: 2rem;
	text-align: center;

	> * {
		margin: 0;
	}

	&::before {
		content: "";
		background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgZmlsbD0iIzAzYTY3OCIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0xNzYsMjMyYTgsOCwwLDAsMS04LDhIODhhOCw4LDAsMCwxLDAtMTZoODBBOCw4LDAsMCwxLDE3NiwyMzJabTQwLTEyOGE4Ny41NSw4Ny41NSwwLDAsMS0zMy42NCw2OS4yMUExNi4yNCwxNi4yNCwwLDAsMCwxNzYsMTg2djZhMTYsMTYsMCwwLDEtMTYsMTZIOTZhMTYsMTYsMCwwLDEtMTYtMTZ2LTZhMTYsMTYsMCwwLDAtNi4yMy0xMi42NkE4Ny41OSw4Ny41OSwwLDAsMSw0MCwxMDQuNDlDMzkuNzQsNTYuODMsNzguMjYsMTcuMTQsMTI1Ljg4LDE2QTg4LDg4LDAsMCwxLDIxNiwxMDRabS0xNiwwYTcyLDcyLDAsMCwwLTczLjc0LTcyYy0zOSwuOTItNzAuNDcsMzMuMzktNzAuMjYsNzIuMzlhNzEuNjUsNzEuNjUsMCwwLDAsMjcuNjQsNTYuM0EzMiwzMiwwLDAsMSw5NiwxODZ2Nmg2NHYtNmEzMi4xNSwzMi4xNSwwLDAsMSwxMi40Ny0yNS4zNUE3MS42NSw3MS42NSwwLDAsMCwyMDAsMTA0Wm0tMTYuMTEtOS4zNGE1Ny42LDU3LjYsMCwwLDAtNDYuNTYtNDYuNTUsOCw4LDAsMCwwLTIuNjYsMTUuNzhjMTYuNTcsMi43OSwzMC42MywxNi44NSwzMy40NCwzMy40NUE4LDgsMCwwLDAsMTc2LDEwNGE5LDksMCwwLDAsMS4zNS0uMTFBOCw4LDAsMCwwLDE4My44OSw5NC42NloiPjwvcGF0aD48L3N2Zz4=");
		display: block;
		height: 3rem;
		width: 100%;
		background-repeat: no-repeat;
		background-position: center;
		margin-bottom: 1rem;
	}
}
