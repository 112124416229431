.formats-overview {
	scroll-margin-top: 12rem;
	grid-column: span 12;
	margin: 2rem auto;

	@media (min-width: $laptop) {
		margin: 5rem auto;
	}

	&__header {
		text-align: center;
	}

	&__content {
		display: grid;
		gap: 1rem;

		@media (min-width: $laptop) {
			grid-template-columns: repeat(2, 1fr);
		}

		&--card {
			display: flex;
			flex-direction: column;
			background-color: $color-03;
			padding: 2rem;
			border-radius: 0.5rem;
			text-decoration: none;
			color: $color-01;
			border: 1px solid transparent;
			transition: all ease-out 150ms;

			@media (hover: hover) {
				&:hover {
					background-color: transparentize($color-02, 0.9);
					border-color: $color-02;
				}
			}

			img {
				max-width: 2.5rem;
				margin-bottom: 2rem;
			}
		}
	}
}
