@import "../../node_modules/normalize.css/normalize.css";

@import "./core-styling/_media-queries";
@import "./core-styling/_color";
@import "./core-styling/_fonts";
@import "./core-styling/_resets";
@import "./core-styling/_links";
@import "./core-styling/_buttons";
@import "./core-styling/_layout";
@import "./core-styling/_headlines";
@import "./core-styling/_paragraphs";
@import "./core-styling/_figure";
@import "./core-styling/_lists";

@import "./page-components/_accordion";
@import "./page-components/_collaboration";
@import "./page-components/_competance-overview";
@import "./page-components/_emphasize-box";
@import "./page-components/_formats-overview";
@import "./page-components/_intro-stage";
@import "./page-components/_page-footer";
@import "./page-components/_page-header";
@import "./page-components/_stage";
@import "./page-components/_tab-content";
@import "./page-components/_tile-grid";
