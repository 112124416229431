.page-header {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	overflow: hidden;
	top: 0;
	right: 0;
	left: 0;
	background-color: $color-00;
	height: 6.75rem;
	border-bottom: 1px solid $color-03;

	z-index: 90;
	transition: all ease-out 200ms;

	@media (min-width: $laptop) {
		justify-content: space-between;
		align-items: center;
		height: auto;

		& button {
			display: none;
		}
	}

	&.opened {
		height: 24rem;
	}

	.logo {
		font-family: "Hind", sans-serif;
		text-decoration: none;
		color: $color-00;
		font-weight: 600;
		font-size: 21px;
		letter-spacing: 1px;

		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		width: 13rem;
		padding: 0.5rem 1rem;
	}

	&__quick-contact {
		display: flex;
		background-color: $color-01;
		color: $color-00;
		width: 100%;

		ul {
			display: flex;
			justify-content: space-between;
			padding: 0.5rem 0.5rem;
			width: 100%;

			@media (min-width: $laptop) {
				justify-content: flex-end;
			}

			a {
				color: $color-00;
				text-decoration: none;
				font-size: 0.75rem;
				padding: 0.5rem;
			}
		}
	}

	&__main-nav {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		width: 100%;

		@media (min-width: $laptop) {
			flex-direction: row;
		}

		ul {
			display: flex;
			flex-direction: column;
			text-align: left;
			padding: 1rem 0;
			align-items: center;

			@media (min-width: $laptop) {
				flex-direction: row;
			}

			li {
				display: flex;
				width: 100%;
			}
		}
	}
}
