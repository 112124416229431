h1,
.h1 {
	font-family: "Hind", sans-serif;
	font-size: 2rem;
	font-weight: 700;
	margin: 0;

	@media (min-width: $mobile-m) {
		font-size: 3rem;
	}

	@media (min-width: $laptop) {
		font-size: 4rem;
	}

	@media (min-width: $laptop-l) {
		font-size: 5rem;
	}
}

h2,
.h2 {
	font-family: "Hind", sans-serif;
	font-size: 1.75rem;
	font-weight: 600;
	line-height: 1.3;
	margin: 0 0 2rem 0;
	color: $color-02;
}

h3,
.h3 {
	font-family: "Hind", sans-serif;
	font-size: 1.3125rem;
	font-weight: 500;
	line-height: 1.3;
	margin: 0 0 1rem 0;
	color: $color-02;
}

h4,
.h4 {
	font-family: "Hind", sans-serif;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.3;
	margin: 0 0 1rem 0;
}
