figure {
  margin: 0;

  img {
    display: block;
    width: 100%;
  }

  video {
    width: 100%;
  }
}