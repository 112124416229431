.accordion {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	.item {
		flex-grow: 1;
		display: grid;
		grid-template-columns: 1fr auto;
		grid-template-rows: auto 1fr;
		flex-direction: column;
		border-radius: 0.5rem;
		text-align: left;
		padding: 2rem;
		background: $color-03;
		color: $color-01;
		border: 0.125rem solid $color-04;
		transition: all ease 150ms;
		cursor: pointer;
		align-items: start;

		@media (min-width: $tablet) {
			align-items: center;
			flex-direction: row;
			justify-content: space-between;
		}

		@media (hover: hover) {
			&:hover {
				background: transparentize($color-02, 0.9);
				border: 0.125rem solid transparentize($color-02, 0.6);
			}
		}

		.header {
			grid-column-start: span 2;
			grid-row-start: 1;
			font-size: 1.125rem;
			font-weight: bold;
			line-height: 1.2;

			@media (min-width: $tablet) {
				grid-column-start: 1;
			}
		}

		.content {
			display: none;
			grid-column-start: span 2;
			grid-row-start: 2;
			margin-top: 1rem;
			overflow-y: hidden;

			p {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.metadata {
			grid-column-start: span 2;
			grid-row-start: 3;
			display: flex;
			gap: 1rem;
			font-size: 1rem;
			font-weight: bold;
			margin-top: 1rem;

			@media (min-width: $tablet) {
				grid-column-start: 2;
				grid-row-start: 1;
				justify-content: end;
				margin-top: 0;
			}

			div {
				display: flex;
				align-items: center;
				gap: 0.25rem;
			}
		}

		&.active {
			cursor: default;
			background: transparentize($color-02, 0.9);
			color: $color-01;
			border-color: transparentize($color-02, 0.6);
			flex-direction: column;
			align-items: start;

			.content {
				display: grid;
				grid-template-rows: 1fr;
			}

			.metadata {
				div:last-child {
					display: flex;
				}
			}
		}
	}
}
