.page-footer {
	background-color: $color-03;
	padding: 2.5rem 0;

	div {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		padding: 0 1.5rem;
		column-gap: 1.5rem;
		max-width: 71.25rem;
		margin: auto;
	}

	section {
		&:nth-child(1) {
			grid-column: 1/-1;

			@media (min-width: $laptop) {
				grid-column: 1/6;
			}
		}

		&:nth-child(2) {
			grid-column: 1/-1;

			@media (min-width: $laptop) {
				grid-column: 6/9;
			}
		}

		&:nth-child(3) {
			grid-column: 1/-1;

			p {
				margin-top: 3.8rem;
			}

			@media (min-width: $laptop) {
				grid-column: 10/12;
			}
		}
	}
}
