.collaboration {
	grid-column: span 12;
	margin: 2rem auto;

	@media (min-width: $laptop) {
		margin: 5rem auto;
	}

	&__header {
		text-align: center;
	}

	&__content {
		display: grid;
		gap: 1rem;

		@media (min-width: $laptop) {
			grid-template-columns: repeat(3, 1fr);
		}

		&--card {
			display: flex;
			flex-direction: column;
			background-color: $color-03;
			border-radius: 0.5rem;
			text-decoration: none;
			color: $color-01;
			border: 1px solid transparent;
			overflow: hidden;

			figure {
				display: flex;
				max-height: 10rem;

				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}

			div {
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				padding: 2rem;

				p {
					flex-grow: 1;
				}
			}
		}
	}
}
