.intro-stage {
	display: flex;
	flex-direction: column-reverse;
	gap: 2rem;
	grid-column-start: 1;
	grid-column-end: 13;
	padding-top: 8rem;
	padding-bottom: 2rem;

	@media (min-width: $laptop) {
		flex-direction: row;
		padding-top: 5rem;
		padding-bottom: 5rem;
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-basis: 40%;

		h1 {
			margin-bottom: 1rem;
			font-size: 2.5rem;
		}

		h2 {
			color: $color-01;
			margin-bottom: 2rem;
		}

		&--actions {
			display: flex;
			gap: 1rem;
		}
	}

	&__video {
		overflow: hidden;
		border-radius: 0.5rem;
		margin: 0;
		flex-basis: 60%;

		video {
			object-fit: cover;
			box-sizing: border-box;
			width: 100%;
			height: 100%;
			margin: 0;
		}
	}
}
