main {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	padding: 0 1.5rem;
	column-gap: 1.5rem;
	max-width: 71.25rem;
	margin: 2rem auto;

	@media (min-width: $laptop) {
		margin: 8rem auto;
	}
}

article {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	column-gap: 1.5rem;
	margin: 0 auto 3rem auto;
	grid-column: 1 / -1;
	width: 100%;
	margin-block-end: auto;

	header,
	section {
		grid-column: 1 / -1;

		@media (min-width: $laptop) {
			grid-column: 2 / 12;
		}
	}

	section {
		grid-column: 1 / -1;
		margin: 0 0 2rem 0;
		margin-block-end: auto;

		@media (min-width: $laptop) {
			grid-column: 2 / 12;
		}

		&.two-cols {
			display: flex;
			flex-direction: column;
			gap: 2rem;

			@media (min-width: $laptop) {
				flex-direction: row;
			}

			figure {
				width: 100%;

				@media (min-width: $laptop) {
					width: 80%;
				}
			}
		}
	}

	figure {
		grid-column: 1 / -1;

		@media (min-width: $laptop) {
			grid-column: 2 / 12;
		}
	}
}
