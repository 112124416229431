.tab-content {
    grid-column: 1/-1;

    nav {
        ul {
            $border-color: $color-04;

            display: flex;
            flex-direction: column;
            border-bottom: 1px solid $border-color;
            border-top: 1px solid $border-color;
            list-style: none;

            @media (min-width: $tablet) {
                flex-direction: row;
            }

            li {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-grow: 1;
                flex-basis: 20%;
                text-align: center;
                margin: 0;
                padding: 0.75rem;
                color: $color-01;


                @media (min-width: $tablet) {
                    padding: 1rem;
                    flex-direction: column;
                }

                &:hover {
                    color: $color-02;
                    cursor: pointer;
                    background-color: transparentize($color-02, 0.9);
                }

                &.active {
                    color: $color-02;
                    background-color: transparentize($color-02, 0.9);
                }

                img {
                    margin-right: 1rem;
                    width: 24px;

                    @media (min-width: $tablet) {
                        width: 32px;
                        margin-right: 0;
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }

    .content {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        column-gap: 1.5rem;
        margin: 3rem auto 0 auto;
        min-height: 30rem;

        div {
            grid-column: 1/-1;

            @media (min-width: $laptop) {
                grid-column: 2/12;
            }

            figure {
                margin: 2rem 0;
            }
        }
    }
}