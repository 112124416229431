// Resets
button,
.button {
	border: none;
	background: none;
	padding: 0;
	margin: 0;
	line-height: 1;
	outline: none;
}

.primary-button {
	background: $color-02;
	color: $color-00;
	text-align: center;
	text-decoration: none;
	border: 0.0625rem solid $color-02;
	padding: 0.75rem 1rem;
	display: block;
	border-radius: 0.25rem;
	transition: all ease-out 150ms;

	@media (hover: hover) {
		&:hover {
			background: darken($color-02, 10%);
			color: $color-03;
		}
	}
}

.outlined-link-button {
	color: $color-02;
	text-align: center;
	text-decoration: none;
	border: 0.0625rem solid $color-02;
	padding: 0.75rem 1rem;
	display: block;
	border-radius: 0.25rem;
	transition: all ease-out 150ms;

	@media (hover: hover) {
		&:hover {
			background: $color-02;
			color: $color-00;
		}
	}
}

.mobile-navigation-menu {
	position: absolute;
	top: 2.75rem;
	right: 0;
	padding: 1rem;
	transition: all ease 240ms;
	z-index: 10;

	> div {
		background-color: $color-01;
		height: 0.125rem;
		width: 1.5rem;
		margin: 0.25rem 0;
		transition: all ease 240ms;
	}

	&.opened {
		transform: rotate(270deg);

		> div {
			$positioning: 4px;
			$negativePositioning: -4px;

			&:first-child {
				transform: rotate(45deg)
					translate3d($positioning, $positioning, 0);
			}

			&:last-child {
				transform: rotate(-45deg)
					translate3d($positioning, $negativePositioning, 0);
			}

			&:nth-child(2) {
				opacity: 0;
			}
		}
	}
}
