.page-navigation-link {
	color: $color-01;
	text-decoration: none;
	width: 100%;
	padding: 1rem;
	transition: all ease 100ms;
	line-height: 1;
	transition: all ease-out 150ms;

	&:hover {
		@media (min-width: $laptop) {
			color: $color-02;
		}
	}

	&.active {
		color: $color-02;

		@media (min-width: $laptop) {
			color: $color-02;
			border-color: $color-02;
		}
	}
}

.footer-navigation-link {
	color: inherit;
	text-decoration: none;
	width: 100%;
	line-height: 1.625;
	transition: all ease-out 150ms;

	&:hover {
		color: $color-02;
	}
}
