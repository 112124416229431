html,
body {
	margin: 0;
	padding: 0;
	font-size: 16px;
	font-family: "Open Sans", sans-serif;
	color: $color-01;
	scroll-behavior: smooth;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
