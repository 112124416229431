.tile-grid {
	grid-column: 1/-1;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
	grid-auto-flow: row;
	grid-gap: 1.5rem;

	.customer-tile {
		position: relative;
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: center;
		justify-items: center;
		justify-content: center;
		min-height: 20rem;
		background-color: $color-03;

		figure {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 70%;

			img {
				mix-blend-mode: darken;
			}
		}

		.description {
			opacity: 0;
			background-color: transparentize($color-01, 0.1);
			position: absolute;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 2rem;
			text-align: center;
			transition: all ease 250ms;
			flex-basis: 100%;
			flex-grow: 1;
			inset: 0;
			color: $color-00;
			hyphens: auto;

			&:hover {
				opacity: 1;
			}
		}
	}
}
