.stage {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $color-01;
	height: 100vw;
	color: $color-00;
	text-align: center;
	padding: 0 1rem;
	margin-top: 5rem;

	@media (min-width: $laptop) {
		height: 70vh;
	}
}

.index {
	background-image: url(../files/stage-images/Closeup_Pascalle_Gruen.jpg);
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: cover;

	@media (min-width: $laptop) {
		background-position: center top 40%;
		background-size: cover;
	}

	@media (min-width: $desktop) {
		background-position: center top 40%;
		background-size: cover;
	}
}

.themes {
	background-image: url(../files/stage-images/portfolio.jpg);
	background-position: right 40% center;
	background-size: cover;

	@media (min-width: $laptop) {
		background-position: center center;
	}
}

.customer {
	background-image: url(../files/stage-images/battle-black-blur-board-game.jpg);
	background-position: left 46% center;
	background-size: cover;

	@media (min-width: $laptop) {
		background-position: top 20% center;
	}
}

.formats {
	background-image: url(../files/stage-images/formats.jpg);
	background-position: left 46% center;
	background-size: cover;

	@media (min-width: $laptop) {
		background-position: center center;
	}
}

.privacy {
	background-image: url(../files/stage-images/privacy.jpg);
	background-position: left 46% center;
	background-size: cover;

	@media (min-width: $laptop) {
		background-position: center center;
	}
}

.imprint {
	background-image: url(../files/stage-images/impressum.jpg);
	background-position: left 46% center;
	background-size: cover;

	@media (min-width: $laptop) {
		background-position: center center;
	}
}

.matrix {
	background-image: url(../files/stage-images/matrix.jpg);
	background-position: center center;
	background-size: cover;

	@media (min-width: $laptop) {
		background-position: center center;
	}
}

.team-organisation {
	background-image: url(../files/stage-images/team-orga.jpg);
	background-position: center center;
	background-size: cover;

	@media (min-width: $laptop) {
		background-position: center center;
	}
}
