article ul,
.ul {
	padding: 0 0 0 1rem;
	margin-bottom: 0.75rem;

	li {
		position: relative;
		margin-bottom: 0.5rem;
		line-height: initial;

		&:last-child {
			margin-bottom: 0;
		}

		&:before {
			content: "•";
			display: block;
			position: absolute;
			color: $color-02;
			left: -1rem;
			font-weight: 700;
		}
	}
}

ol {
	padding: 0 0 0 1rem;
	margin-bottom: 0.75rem;

	li {
		position: relative;
		margin-bottom: 0.5rem;
		line-height: initial;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
